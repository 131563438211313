body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mainLayout {
  position: absolute;
  width: 100%
}

.backgroundImage {
  background-image: url("./img/background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;
}

.landingForm { 
  max-width: 500px;
  margin: 0 auto;
}


.grid-container {
  display: grid;
  column-gap: 50px;
  grid-template-columns: auto auto auto;
}
@media (max-width: 991px) {
  .grid-container {
    display: grid;
    row-gap: 10px;
    column-gap: auto;
    margin: auto;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
  }
}

.galleryPhoto {
  width: 100%;
  margin: auto;
}

@media (max-width: 991px) {
  .galleryPhoto {
    width: 100%;
    margin: auto;
  }
}

.circle {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
  text-align: center;
  background: #000
}


.panel {
  background: rgba(250, 250, 250, 0.72);
  /* -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
  box-shadow: 0px 0px 50px rgba(100,0,0,25); */
  margin: 20px auto 20px auto;
  max-width: 1000px;
  padding: 50px;

  border-radius: 4px;

}
@media (max-width: 991px) {
  .panel {
    margin: 4px;
    border-radius: 4px;
    padding: 25px;
  }
}

.header {
  margin: auto;
  margin-bottom: 40px;
}
.img-centre {
  object-fit: contain;
  margin: auto;
  display: block;
}

.download-button {
  width: fit-content;
  font-family: sans-serif;
  color: white;
  background-color: DodgerBlue;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 15px;
  display: inline-flex;
  border-radius: 5px;
}

.download-button:hover {
  background-color: RoyalBlue;
}

.addToCart-button {
  width: fit-content;
  font-family: sans-serif;
  color: white;
  background-color: DodgerBlue;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 15px;
  display: inline-flex;
  border-radius: 5px;
}

.addToCart-button:hover {
  background-color: RoyalBlue;
}

.loading-spinner {
  margin: auto;
  height: 100%;
  padding: 100px;
  text-align: center;
  font-size: 10vh;
  color: rgb(195, 116, 43);
}

.error-message {
  margin: auto;
  height: 100%;
  padding: 100px;
  text-align: center;
}

.error-icon {
  font-size: 10vh;
  color: darkred;
}

.image-card {
}

.image-subtitle {
  width: fit-content;
  margin: auto;
  padding: 4px;
}
.image-subtitle > * {
  margin: 2px;
}

.sub-header {
  width: fit-content;
  margin: auto;
  position: relative;
}






.fmhv .mainGrid>.row>.noFloat.sidebar:after {
  background: rgba(250, 250, 250, 0.72) 0 0;
}

.mainBody {
  background: rgba(250, 250, 250, 0.72);
  -moz-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
  -webkit-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
  box-shadow: 0px 0px 50px rgba(100,0,0,25);
  padding: 10px 50px 10px 50px;
}

@media (max-width: 991px) {
  .sideBarLayout {
    padding: 10px 10px 10px 10px;
    background: rgba(250, 250, 250, 0.72);
  }
  .mainBody {
    padding: 0px;
  }
}

.mainTitle, .description {
  padding: 10px;
  border-radius: 4px;
  background-color: #f7f7f7;
  text-align: center;
}

.mainTitle {
  border-radius: 4px 4px 0px 0px;
}

.fmhv .categoryList .description {
  border-radius: 0px 0px 4px 4px;
  margin-bottom: 10px;
  padding-bottom: 20px;
}

.fmhv .categoryList .category button.btn-primary {
  background-color: #f7f7f7;
}

.fmhv .sideBarLayout .brand img {
  float: left;
}

@media (max-width: 991px) {
  .fmhv .sideBarLayout .brand img {
    float: none;
    margin: auto;
  }
}

.galleryViewView, .galleryListView {
  margin-top: 10px;
  padding: 20px;
  border-radius: 4px 4px 4px 4px;
  background-color: #f7f7f7;
}

.panel-body {
  background-image: url("./img/617115710ff5e.png");
  background-size: 100% 100%;
  padding: 50px;
}

@media (max-width: 991px) {
  .panel-body {
    padding: 50px 50px 50px 50px;
  }
}










h1 {
  font-weight: 100;
  color: white;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

.form {
  background: #0e101c;
  max-width: 400px;
  margin: 0 auto;
}

p.error {
  color: #bf1616;
}

p.error::before {
  display: inline;
  content: "⚠ ";
}

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}

button[type="submit"],
input[type="submit"] {
  background: #D4AF37;
  color: white;
  text-transform: uppercase;
  border: none;
  margin: auto;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 2px;
}

button[type="submit"]:hover,
input[type="submit"]:hover {
  background: hsl(46, 65%, 35%);;
}

button[type="submit"]:active,
input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

button[type="submit"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

.App {
  max-width: 600px;
  margin: 0 auto;
}

button[type="button"] {
  display: block;
  appearance: none;
  background: #333;
  color: white;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

pre {
  color: white;
}

hr {
  margin-top: 30px;
}

button {
  display: block;
  appearance: none;
  margin-top: 40px;
  border: 1px solid #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

.positionLeft {
  float: right;
}



/* Cart CSS */

.cartSummaryTotalContainer {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  height: 25px;
  background-color: #fff;
  left: 0px;
  padding: 2% 5% 2% 5%;
  border-start-start-radius: 5px;
  margin:auto;
  grid-template-columns: auto auto
}

.cartTotalDetails > * {
  display: inline;
  padding-left: 10px;
}

@media (max-width: 991px) {
  .cartSummaryTotalContainer {
    position: fixed;
  }
  
  .cartLargeMediaQuery {
    display: none 
  }
}

@media (min-width: 991px) {
  /* .cartSummaryMediaQuery {
    display: none
  } */
  .cartSmallMediaQuery {
    display: none 
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  padding-bottom: 15px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.checkout-button {
  width: fit-content;
  font-family: sans-serif;
  color: white;
  background-color: DodgerBlue;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 15px;
  display: inline-flex;
  border-radius: 5px;
}

.checkout-button:hover {
  background-color: RoyalBlue;
}

.cartActions {
  padding-top: 5px;
}

.adminGallery{
  max-height: 100px;
  padding: 10px;
  flex-wrap: wrap;
}
